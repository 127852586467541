@use '../../styles/yr' as *;

.daily-weather-list__headers {
  @include sticky-app-top;
  background-color: var(--color-background-lower);
  z-index: $z-index-sticky-heading;

  display: grid;

  @include mq-gt($mq-990) {
    grid-template-columns: size(26) 2fr 2fr 1fr;
    grid-template-rows: size(4);
    grid-template-areas: '. symbol-header forecast-header .';
    column-gap: size(3);
    padding-left: size(2);
    padding-right: size(2);
    margin: 0 size($layout-size-horizontal);
  }

  @include mq-lte($mq-990) {
    grid-template-columns: 3fr 2fr 2fr size(3);
    grid-template-rows: size(4);
    grid-template-areas: '. symbol-header symbol-header .';
    padding: 0 size(1) 0 size(2);
  }

  @include mq-lte($mq-600) {
    grid-template-columns: size(12) auto auto size(3);
  }
}

.daily-weather-list__symbols-headers,
.daily-weather-list__forecast-headers {
  align-self: flex-end;
}

.daily-weather-list__symbols-headers {
  display: grid;
  grid-area: symbol-header;
  grid-template-columns: 1fr 1fr 1fr 1fr;

  @include mq-gt($mq-990) {
    grid-template-areas: 'night morning afternoon evening';
  }

  @include mq-lte($mq-990) {
    grid-template-areas: 'night day day evening';
  }
}

.daily-weather-list__header {
  justify-self: center;
  margin-bottom: size(1);
}

// We need to specify grid areas since IE does not support auto-layout
.daily-weather-list__header--night {
  grid-area: night;
}

.daily-weather-list__header--morning {
  grid-area: morning;
}

.daily-weather-list__header--afternoon {
  grid-area: afternoon;
}

.daily-weather-list__header--day {
  grid-area: day;
}

.daily-weather-list__header--evening {
  grid-area: evening;
}

.daily-weather-list__forecast-headers {
  display: grid;

  grid-area: forecast-header;
  grid-template-columns: 1.2fr 1fr 1fr;
  grid-template-areas: 'temperature precipitation wind';
  column-gap: size(1);

  @include mq-lte($mq-990) {
    display: none;
  }
}

// We need to specify grid areas since IE does not support auto-layout
.daily-weather-list__header--wind {
  grid-area: wind;
  justify-self: end;
}

.daily-weather-list__header--precipitation {
  grid-area: precipitation;
  justify-self: end;
}

.daily-weather-list__header--temperature {
  grid-area: temperature;
  text-align: right;
  justify-self: end;
}

.daily-weather-list__header--day {
  @include mq-gt($mq-990) {
    display: none;
  }
}

.daily-weather-list__header--morning,
.daily-weather-list__header--afternoon {
  @include mq-lte($mq-990) {
    display: none;
  }
}

.daily-weather-list__intervals {
  margin: 0 size(1);
  @include mq-gt($mq-990) {
    margin: 0 size($layout-size-horizontal);
  }
}
